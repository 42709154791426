import React from 'react';
import { navigate } from 'gatsby';
import OpenSourcePageWrapper from '@page-components/open-source/OpenSourcePageWrapper';
import links from '@constants/links';

const OpenSourcePage = () => {
  if (typeof window !== 'undefined') {
    navigate(links.openSource.arrayStorage);
  }

  return <OpenSourcePageWrapper />;
};

export default OpenSourcePage;
